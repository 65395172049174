import PropTypes from 'prop-types';
import React from 'react';
import { removeLastSegment } from 'utils/helpers';
import Container from './wrapper.style';

/**
 * Wrap component with collapsible area.
 *
 * @param {Object} props The component props.
 * @param {JSX.Element} props.children The child component to wrap.
 * @param {boolean} props.isCollapsed Is area collapsed.
 *
 * @return {JSX.Element}
 */
export default function Wrapper({ children, isCollapsed }) {
  const pathName = window.location.pathname;

  const pathWithoutLastSegment = removeLastSegment(pathName);
  const zeroPadding = pathWithoutLastSegment === '';

  return (
    <Container zeroPadding={zeroPadding} isCollapsed={isCollapsed}>
      {children}
    </Container>
  );
}

Wrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
};
