import { ReactComponent as ClaimAddressIcon } from 'assets/icons/claim-address.svg';
import { ReactComponent as EventsIcon } from 'assets/icons/events-icon.svg';
import { ReactComponent as knowledgeCenterIcon } from 'assets/icons/knowledgeCenter/idea.svg';
import { ReactComponent as QuestionIcon } from 'assets/icons/knowledgeCenter/questionIcon.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { ReactComponent as RequestsIcon } from 'assets/icons/request-icon.svg';
import { ReactComponent as UserRole } from 'assets/icons/user-roles.svg';
import { ReactComponent as Bids } from 'assets/icons/bid.svg';
import { ReactComponent as Revenues } from 'assets/icons/revenues.svg';
import { ReactComponent as Listings } from 'assets/icons/listings.svg';
import { ReactComponent as Subscriptions } from 'assets/icons/subscriptions.svg';
import { ReactComponent as Billings } from 'assets/icons/billings.svg';
import { ReactComponent as Reports } from 'assets/icons/reports.svg';
import { ReactComponent as Up } from 'assets/chevron-up.svg';
import { ReactComponent as Down } from 'assets/chevron-down.svg';
import { useUser } from 'contexts/UserContext';
import React, { useState } from 'react';
import { getStrokeWidth } from 'utils/helpers';
import {
  BroadNavContainer,
  CloseMenuIcon,
  ExpandableContainer,
  ExpandableMenuItem,
  IconContainer,
  Logo,
  LogoContainer,
  MenuIcon,
  MenuLink,
  Nav,
  ProfileWrapper,
  ExpandableMenuButton,
  MobileProfileWrapper,
  DashboardLink,
} from './navbar.styles';

const noteLinks = [{ Icon: Listings, title: 'Notes', to: '/noteXchange/notes' }];

const reverifiLinks = [
  { Icon: Reports, title: 'Reports', to: '/reports' },
  { Icon: UserRole, title: 'Users', to: '/users' },
  { Icon: EventsIcon, title: 'Events', to: '/events' },
  { Icon: ClaimAddressIcon, title: 'Users Claims', to: '/claim-address' },
  { Icon: Bids, title: 'Bids', to: '/bids' },
  { Icon: Revenues, title: 'Agents’ Revenues', to: '/agents-revenues' },
  { Icon: Listings, title: 'All Listings', to: '/listings' },
  { Icon: Listings, title: 'Exclusive Listings', to: '/exclusive-listings' },
  { Icon: Subscriptions, title: 'Subscriptions', to: '/subscriptions' },
  { Icon: Billings, title: 'Billings', to: '/billings' },
  { Icon: QuestionIcon, title: 'FAQs', to: '/FAQs' },
  { Icon: knowledgeCenterIcon, title: 'Knowledge Center', to: '/knowledge-center' },
  { Icon: RequestsIcon, title: 'Requests', to: '/requests' },
];

/**
 * App navbar.
 *
 * @return {JSX.Element}
 */
function Navbar() {
  const { userInfo, logout } = useUser();
  const [isExpanded, setIsExpanded] = useState(false);
  const [noteExpanded, setNoteExpanded] = useState(false);
  const [revExpanded, setRevExpanded] = useState(false);

  return (
    <Nav>
      <BroadNavContainer>
        <IconContainer>
          {!isExpanded ? (
            <MenuIcon onClick={() => setIsExpanded(true)} />
          ) : (
            <CloseMenuIcon onClick={() => setIsExpanded(false)} />
          )}
        </IconContainer>
        <LogoContainer>
          <Logo />
          <MobileProfileWrapper>Admin</MobileProfileWrapper>
        </LogoContainer>
        <ProfileWrapper>
          <span>Welcome, {userInfo?.name}</span>
        </ProfileWrapper>
      </BroadNavContainer>
      <ExpandableContainer isExpanded={isExpanded}>
        <DashboardLink to="/" onClick={() => setIsExpanded(false)}>
          <ExpandableMenuItem>
            <h4>Dashboard</h4>
          </ExpandableMenuItem>
        </DashboardLink>
      </ExpandableContainer>

      <ExpandableContainer isExpanded={isExpanded}>
        <>
          <ExpandableMenuButton
            isCollapsed={isExpanded}
            onClick={() => setNoteExpanded(!noteExpanded)}
          >
            {isExpanded && <span>NoteXchange</span>} {noteExpanded ? <Up /> : <Down />}
          </ExpandableMenuButton>
          <ExpandableContainer isExpanded={noteExpanded}>
            {noteLinks.map(({ Icon, title, to }) => (
              <MenuLink
                key={title}
                $isCollapsed={isExpanded}
                to={to}
                onClick={() => setIsExpanded(false)}
              >
                <ExpandableMenuItem
                  isFAQsIcon={title === 'FAQs'}
                  isReportsIcon={title === 'Reports'}
                  strokeWidth={getStrokeWidth(title)}
                >
                  <Icon />
                  <h4>{title}</h4>
                </ExpandableMenuItem>
              </MenuLink>
            ))}
          </ExpandableContainer>
          <ExpandableMenuButton
            isCollapsed={isExpanded}
            onClick={() => setRevExpanded(!revExpanded)}
          >
            {isExpanded && <span>Reverifi</span>} {revExpanded ? <Up /> : <Down />}
          </ExpandableMenuButton>
          <ExpandableContainer isExpanded={revExpanded}>
            {reverifiLinks.map(({ Icon, title, to }) => (
              <MenuLink
                key={title}
                $isCollapsed={isExpanded}
                to={to}
                onClick={() => setIsExpanded(false)}
              >
                <ExpandableMenuItem
                  isFAQsIcon={title === 'FAQs'}
                  isReportsIcon={title === 'Reports'}
                  strokeWidth={getStrokeWidth(title)}
                >
                  <Icon />
                  <h4>{title}</h4>
                </ExpandableMenuItem>
              </MenuLink>
            ))}
          </ExpandableContainer>

          <ExpandableMenuItem
            onClick={() => {
              setIsExpanded(false);
              logout();
            }}
            padding
          >
            <Logout />
            <h4>Log Out</h4>
          </ExpandableMenuItem>
        </>
      </ExpandableContainer>
    </Nav>
  );
}

export default Navbar;
