/**
 * capitalize First Letter function
 *
 * @param {String} string text to be capitalize first letter
 *
 * @return {String} capitalized string
 */
// eslint-disable-next-line import/prefer-default-export
export const toUpperCaseFirstLetter = (string) =>
  string ? `${string.charAt(0).toUpperCase()}${string.slice(1)}` : string;

/**
 *function to capitalize the every first letter of the string.
 *
 * @param {String} statement statement to be capitalized.
 *
 * @return {String} capitalized statement.
 */
export const toUpperCaseEachWord = (statement) => {
  if (statement) {
    const words = statement.split(' ');

    return words
      .map((word) => {
        return word[0].toUpperCase() + word.substring(1);
      })
      .join(' ');
  }
  return '';
};
/**
 * Handle text input function prevent enter other types than letters
 *
 * @param {object} target input target object
 *
 */
export const handleTextInput = ({ target }) => {
  target.value = target.value.replace(
    // eslint-disable-next-line no-useless-escape
    /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|0-9]/g,
    ''
  );
};

/**
 * Handle number input function prevent enter other types than numbers
 *
 * @param {object} target input target object
 *
 */
export const handleNumberInput = ({ target }) => {
  target.value = target.value.replace(
    // eslint-disable-next-line no-useless-escape
    /[-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|a-zA-Z]/g,
    ''
  );
};

/**
 * generate Label Value Pairs function
 *
 * @param {Array} data array of data
 *
 * @returns {Array} array or reshaped data
 */
export const generateLabelValuePairs = (data) =>
  data?.map((item) => ({ label: item, value: item }));

/**
 * change date format
 *
 * @param {String} date date
 *
 * @returns {String} date in new format
 */
export const formatDate = (date) => new Date(date).toLocaleDateString();

/**
 * change time format
 *
 * @param {String} date date
 *
 * @returns {String} time in new format
 */
export const formatTime = (date) => new Date(date).toLocaleTimeString();

/**
 *  get City Country Zip Code from react autocomplete
 *
 * @param {Array} results autocomplete search results
 *
 * @returns {Array} array of city, country, zipCode
 */
export const getCityCountryZipCode = (results) =>
  results
    ?.filter(
      (item) =>
        item.types.includes('postal_code') ||
        item.types.includes('administrative_area_level_1') ||
        item.types.includes('country')
    )
    .reduce(
      (acc, item) => ({
        ...acc,
        // eslint-disable-next-line no-nested-ternary
        [item.types.includes('postal_code')
          ? 'zipCode'
          : item.types.includes('administrative_area_level_1')
          ? 'city'
          : 'country']: `${item.long_name}, ${item.short_name}`,
      }),
      {}
    );

/**
 *  Get Stroke Width for icon
 *
 * @param {String} title title of menu item
 *
 * @returns {Number} width of stroke
 */
export const getStrokeWidth = (title) => {
  if (title === 'Users Claims') return 8;
  if (title === 'Events') return 0.5;
  return null;
};

/**
 * custom select theme function to change select default colors
 *
 * @param {Object} theme theme object from the select component
 * @param {Object} error input useForm error
 * @param {Object} themeColors theme colors
 *
 */
export const customSelectTheme = (theme, themeColors, error) => ({
  ...theme,
  colors: {
    ...theme?.colors,
    dangerLight: themeColors?.dangerLight,
    primary: error ? themeColors.error : themeColors?.primary,
    primary25: themeColors?.primary25,
  },
});

/**
 * format amounts function
 *
 * @param {Number} amount the amount to format
 *
 */
export const amountFormatter = (amount) => `$${new Intl.NumberFormat().format(amount)}`;

/**
 * Handle bath room number input
 *
 * @param {object} target input target object
 *
 */
export const handleBathroomNumberInput = ({ target }) => {
  // eslint-disable-next-line prefer-regex-literals
  const validNumber = new RegExp(/^\d{0,9}(\.\d{0,1})?$/);

  if (!validNumber.test(target.value)) target.value = target.value.slice(0, -1);
};

/**
 * Check if the user is VIP
 *
 * @param {Array} data User Subscriptions
 *
 * @returns {Boolean} if user is VIP
 */
export const isVIPMember = (data = []) =>
  data?.some(({ planId }) => planId === 3 || planId === 5 || planId === 6 || planId === 7);

/**
 * Formats a number to display in a human-readable format 1k,1m,.
 *
 * @param {number} value - The number to be formatted.
 * @returns {string} - The formatted number.
 */
export const formatNumber = (value) => {
  if (value < 1000) {
    return value.toString();
  }

  if (value >= 1e6) {
    const millionPart = (value / 1e6).toFixed(1);
    return millionPart.endsWith('.0') ? `${millionPart.slice(0, -2)}m` : `${millionPart}m`;
  }

  if (value >= 1e3) {
    const thousandPart = (value / 1e3).toFixed(1);
    return thousandPart.endsWith('.0') ? `${thousandPart.slice(0, -2)}k` : `${thousandPart}k`;
  }

  return value.toString();
};

/**
 * Removes the last segment (trailing slash or part of a path) from a given path string.
 *
 * @param {string} path The input path from which to remove the last segment.
 * @returns {string} The modified path with the last segment removed.
 */
export const removeLastSegment = (path) =>
  path.charAt(path.length - 1) === '/' ? path.slice(0, -1) : path;

/**
 * Generates an array of years from the specified start year to the current year.
 *
 * @param {number} startYear - The starting year for the array.
 * @returns {number[]} An array of years from startYear to the current year.
 */
export const generateYearsArray = (startYear) => {
  const currentYear = new Date().getFullYear();

  const yearsArray = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  );

  return yearsArray;
};
