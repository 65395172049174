import Layout from 'components/shared/Layout';
import RequireAuth from 'components/shared/RequireAuth';
import Toast from 'components/shared/Toast';
import { useShowToast } from 'contexts/showToastContext';
import { UserProvider } from 'contexts/UserContext';
import useShowToastBar from 'hooks/use-show-toast-bar';
import React from 'react';
import { Route, Routes, matchPath, useLocation } from 'react-router-dom';
import routes from 'routes';

function App() {
  const { showToast, setShowToast } = useShowToast();
  useShowToastBar(showToast, setShowToast);
  const { pathname } = useLocation();

  const useLayout = routes.find((ele) => matchPath(ele, pathname))?.withLayout;

  const Continer = useLayout ? Layout : React.Fragment;

  return (
    <UserProvider>
      <Continer>
        <React.Suspense fallback={<span />}>
          <Routes>
            {routes.map(({ authRequired, label, path, component: Component }) => {
              const element = authRequired ? (
                <RequireAuth>
                  <Component />
                </RequireAuth>
              ) : (
                <Component />
              );
              return <Route key={label} path={path} exact={path} element={element} />;
            })}
          </Routes>
        </React.Suspense>
      </Continer>
      {showToast?.isActive && (
        <Toast isPositionFixed status={showToast.status} message={showToast.text} />
      )}
    </UserProvider>
  );
}

export default App;
