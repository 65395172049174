import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo, useState } from 'react';

export const TostContext = createContext();

TostContext.displayName = 'TostContext';

/**
 * show toast context provider to manage toast open and close.
 *
 * @param {JSX.Element} props.children Child nodes to render and pass context.
 *
 * @return {JSX.Element}
 */
function ShowToastProvider({ children }) {
  const [showToast, setShowToast] = useState({
    isActive: false,
    status: 'success',
    text: '',
  });

  const value = useMemo(() => ({ setShowToast, showToast }), [showToast]);

  return <TostContext.Provider value={value}>{children}</TostContext.Provider>;
}

ShowToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useShowToast = () => useContext(TostContext);

export default ShowToastProvider;
