const colors = {
  alabaster: '#fafafa',
  azure: '#0084fc',
  black: '#000000',
  blue: '#006aff',
  cornflowerBlue: '#3377ff',
  cyanBlue: '#4d8eff',
  dustyGray: '#979797',
  gray: '#6f7076',
  green: '#b2d235',
  lightBlue: '#82DAE9',
  lightGray: '#ebebeb',
  lightGreen: '#B7CF53',
  liver: '#524d4d',
  matterhorn: '#545454',
  mercury: '#e4e4e4',
  midGray: '#d8d8d8',
  mineShaft: '#222222',
  orange: '#fe5d26',
  osloGray: '#8c8b8b',
  pink: '#fde9ed',
  red: '#ff0000',
  sectionSeperator: '#F8F8F8',
  skyBlue: '#A6DAE8',
  softGray: '#8C94AD',
  white: '#ffffff',
  whiteSand: '#f5f5f5',
  wildSand: '#f4f4f4',
};

export default colors;
