import styled from 'styled-components';
import mq from 'styles/media-query';

const Container = styled.div`
  padding: ${({ zeroPadding }) => (zeroPadding ? 'unset' : '1rem')};
  width: 100vw;
  ${mq.tablet`
    min-width: 70vw;
      max-width: ${({ isCollapsed }) => (isCollapsed ? '70vw' : '85vw')};
      max-width: ${({ zeroPadding }) => zeroPadding && 'unset'};
      transition: ${({ isCollapsed }) =>
        isCollapsed ? 'ease-in-out 0.02s;' : 'ease-in-out 0.4s;'}; 
      padding: ${({ zeroPadding }) => (zeroPadding ? 'unset' : '2rem')};

  `};
  ${mq.desktop`
      min-width: 80vw;
      max-width: ${({ isCollapsed }) => (isCollapsed ? '85vw' : '100vw')};
      transition: ${({ isCollapsed }) =>
        isCollapsed ? 'ease-in-out 0.02s;' : 'ease-in-out 0.4s;'};
  `}
`;

export default Container;
