import useEffectOnce from 'hooks/use-effect-once';
import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import auth from 'services/auth';
import { getUserData, getUserInfo } from 'services/user';
import tokenUtil from 'utils/token';

export const UserContext = createContext({
  /**
   * Is user logged in.
   *
   * @type {string}
   */
  isLoggedIn: false,

  /**
   * Login the user.
   *
   * @type {Function}
   */
  login: undefined,

  /**
   * Logout the user.
   *
   * @type {Function}
   */
  logout: undefined,

  /**
   * User data.
   *
   * @type {Object}
   */
  userInfo: undefined,
});

UserContext.displayName = 'UserContext';

/**
 * User context provider to manage user auth operations.
 *
 * @param {Object}      props
 * @param {JSX.Element} props.children Child nodes to render and pass context.
 *
 * @return {JSX.Element}
 */
export function UserProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [userInfo, setUserInfo] = useState();
  const navigate = useNavigate();

  /**
   * Logout user.
   */
  const logout = useCallback(() => {
    tokenUtil.removeToken();
    setIsLoggedIn(false);
    setUserInfo(undefined);
    navigate('/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Update the state based on user token.
   *
   * @param {string} token User token.
   */
  const setTokenData = useCallback(
    async (token) => {
      const { email, id, name, phone, points, createdAt, isVerified } =
        tokenUtil.decodeToken(token) || {};

      // if token not decoded correctly consider the token is invalid and logout the user
      if (!(email && name)) {
        logout();
        return;
      }
      tokenUtil.setToken(token);
      setIsLoggedIn(true);
      try {
        const { user } = await getUserData(id);
        setUserInfo({
          createdAt: user?.createdAt,
          email: user?.email,
          id: user?.id,
          isVerified: user?.isVerified,
          name: user?.name,
          phone: user?.phone,
          points: user?.points,
          token,
        });
      } catch (err) {
        setUserInfo({
          createdAt,
          email,
          id,
          isVerified,
          name,
          phone,
          points,
          token,
        });
      }

      const { image, thumbnailImage } = await getUserInfo();
      if (image) setUserInfo((prev) => ({ ...prev, image, thumbnailImage }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logout]
  );

  const redirectHandler = useCallback(
    async (token) => {
      const { email, name } = tokenUtil.decodeToken(token) || {};

      // if token not decoded correctly consider the token is invalid and logout the user
      if (!(email && name)) {
        logout();
        return;
      }

      navigate('/');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logout]
  );
  /**
   * Login user.
   *
   * @param {string} email User email.
   * @param {string} password User password.
   */
  const login = useCallback(
    async (email, password) => {
      const { token } = await auth.login(email, password);
      setTokenData(token);
      redirectHandler(token);
    },
    [setTokenData, redirectHandler]
  );

  useEffectOnce(() => {
    const token = tokenUtil.getToken();
    if (!token) {
      setIsLoggedIn(false);
      return;
    }
    setTokenData(token);
  });

  const value = useMemo(
    () => ({
      isLoggedIn,
      login,
      logout,
      setUserInfo,
      userInfo,
    }),
    [isLoggedIn, login, logout, userInfo]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

UserProvider.propTypes = {
  children: PropTypes.node,
};

UserProvider.defaultProps = {
  children: null,
};

export const useUser = () => useContext(UserContext);
