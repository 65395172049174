import { lazy } from 'react';

/**
 * Objects of routes to determine correct component to load based on URL.
 *
 * @property {boolean} adminLayout Wrap the page with admin layout.
 * @property {JSX.Element} component React component to render.
 * @property {bool} disableFooter Whether footer disabled.
 * @property {string} label A name used for react `key` prop.
 * @property {string} path Any valid URL path.
 */
const routes = [
  {
    authRequired: true,
    component: lazy(() => import('pages/Home')),
    label: 'home',
    path: '/',
    title: 'Home',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/Event')),
    label: 'events',
    path: '/events',
    title: 'Events',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/Event/form')),
    label: 'events',
    path: '/event/new',
    title: 'Events',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/Event/form')),
    label: 'events',
    path: 'events/:id',
    title: 'Events',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/Reports')),
    label: 'reports',
    path: '/reports',
    title: 'Reports',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/EventsReports')),
    label: 'events-reports',
    path: '/reports/events-reports',
    title: 'Events Reports',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/BidsReports')),
    label: 'bids-reports',
    path: '/reports/bids-reports',
    title: 'Bids Reports',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/ListingReports')),
    label: 'listing-reports',
    path: '/reports/listing-reports',
    title: 'Listing Reports',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/UsersReports')),
    label: 'users-reports',
    path: '/reports/users-reports',
    title: 'Users Reports',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/faqs')),
    label: 'FAQs',
    path: '/FAQs',
    title: 'FAQs',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/KnowledgeCenter')),
    label: 'knowledge-center',
    path: '/knowledge-center',
    title: 'Knowledge Center',
    withLayout: true,
  },
  {
    authRequired: false,
    component: lazy(() => import('pages/Transactions')),
    label: 'transactions',
    path: '/transactions',
    title: 'Transactions',
    withLayout: true,
  },
  {
    component: lazy(() => import('pages/Login')),
    label: 'login',
    path: '/login',
    title: 'Login',
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/Listings')),
    label: 'all-listings',
    path: '/listings',
    title: 'All Listings',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/Users')),
    label: 'users',
    path: '/users',
    title: 'Users',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/MyProfile')),
    label: 'my-profile',
    path: '/my-profile',
    title: 'My Profile',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/ClaimAddress')),
    label: 'claim addresses',
    path: '/claim-address',
    title: 'Claim Addresses',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/Bids')),
    label: 'bids',
    path: '/bids',
    title: 'Bids',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/UsersBids')),
    label: 'users bids',
    path: '/bids/:id',
    title: 'Users Bids',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/AgentRevenues')),
    label: 'agents’ revenues',
    path: '/agents-revenues',
    title: 'Agents’ Revenues',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/ExclusiveListings')),
    label: 'exclusive listings',
    path: '/exclusive-listings',
    title: 'Exclusive Listings',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/Subscriptions')),
    label: 'subscriptions',
    path: '/subscriptions',
    title: 'Subscriptions',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/Billings')),
    label: 'billings',
    path: '/billings',
    title: 'Billings',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/RequestsPage')),
    label: 'requests',
    path: '/requests',
    title: 'Requests',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/noteXchange/Notes')),
    label: 'notes',
    path: '/noteXchange/notes',
    title: 'Note',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/noteXchange/Notes/form')),
    label: 'notes edit/create',
    path: '/noteXchange/notes/:id',
    title: 'Note Edit/Create',
    withLayout: true,
  },
  {
    authRequired: true,
    component: lazy(() => import('pages/dashboard')),
    label: 'home',
    path: '/',
    title: 'Home',
    withLayout: true,
  },
];

export default routes;
