import { ReactComponent as RawLogo } from 'assets/icons/logo.svg';
import { Menu } from 'react-feather';
import CloseIcon from 'react-feather/dist/icons/x';
import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import colors from 'styles/colors';
import mq from 'styles/media-query';

const fadeOut = keyframes`
  from {
    max-height: 0;
    opacity: 0;
  }
  
  to {  
    max-height: 42rem;
    opacity: 1;
  }
`;

const fadeIn = keyframes`
  from {
    max-height: 42rem;
    opacity: 1;
  }
  
  to {
    max-height: 0;
    opacity: 0;
  }
`;

export const BroadNavContainer = styled.div`
  background-color: ${colors.mineShaft};
  color: ${colors.white};
  display: flex;
  font-size: 0.875rem;
  height: 5rem;
  padding: 0 1rem;
  ${mq.tablet`
    align-items: stretch;
    justify-content: start;
  `}
`;

export const IconContainer = styled.div`
  display: flex;

  > svg {
    margin: auto;
  }
`;

export const LogoContainer = styled.div`
  flex-grow: 0;
  display: flex;
  margin-left: 1rem;

  > svg {
    margin: auto;
  }

  ${mq.tablet`
    padding-left: 0;

    > svg {
      margin-left: 6rem;
    }
  `}
`;

export const Nav = styled.nav`
  position: sticky;
  z-index: 19000;
  top: 0;
`;

export const MenuIcon = styled(Menu)`
  color: white;
  height: 2rem;
  width: 2rem;

  ${mq.tablet`
    display: none
  `}
`;

export const CloseMenuIcon = styled(CloseIcon)`
  color: white;
  height: 2rem;
  width: 2rem;

  ${mq.tablet`
    display: none
  `}
`;

export const Logo = styled(RawLogo)`
  width: 6.25rem;
`;

export const UserImage = styled.img`
  border-radius: 50%;
  height: 2.65rem;
  width: 2.65rem;
  margin-right: 0.2rem;
  object-fit: cover;
  ${mq.desktop`
  height: 3.2rem;
  width: 3.2rem;
  margin-right:  0.3rem;
  `}
`;

export const ProfileWrapper = styled.div`
  display: none;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0 0 0 auto;

  ${mq.tablet`
    display: flex;
  `}
`;

export const MobileProfileWrapper = styled.div`
  display: flex;
  margin-top: 2.4rem;
  margin-left: 0.5rem;
  color: ${colors.green};
  font-size: 0.94rem;
  font-weight: 400;
`;

export const ExpandableContainer = styled.div`
  animation: ${({ isExpanded }) => (isExpanded ? fadeOut : fadeIn)} ease-in-out;
  animation-duration: 0.4s;
  max-height: ${({ isExpanded }) => (isExpanded ? '100%' : '0')};
  overscroll-behavior: contain;
  width: 100%;
  background-color: ${colors.mineShaft};
  display: ${({ isExpanded }) => (isExpanded ? 'flex' : 'none')};
  flex-direction: column;
  gap: 1rem;

  ${mq.tablet`
    display:none;
  `}
`;

export const ExpandableMenuButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: ${colors.green};
  cursor: pointer;
  display: flex;
  font-size: 1.15rem;
  font-weight: 500;
  height: 2.25rem;
  justify-content: space-between;
  padding: ${({ isCollapsed }) => (isCollapsed ? '0 2.4rem 0 1rem' : '0 0.5rem')};

  span {
    @keyframes show {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    animation-name: show;
    animation-duration: 1s;
  }

  &:not(:first-of-type) {
    margin-top: 2rem;
  }

  svg {
    height: 1.56rem;
    width: 1.56rem;
  }

  img {
    height: 2.25rem;
    width: 2.25rem;
  }
`;

export const ExpandableMenuItem = styled.div`
  align-items: flex-start;
  display: flex;
  gap: 1rem;
  justify-content: start;
  margin-top: ${({ padding }) => (padding ? '0.3rem' : '0.7rem')};
  padding-left: ${({ padding }) => (padding ? '3rem' : '1rem')};

  svg {
    max-width: 1.8rem;
    max-height: 1.6rem;
    width: auto;

    ${({ strokeWidth }) =>
      strokeWidth &&
      `
      g {
        stroke: white;
        stroke-width: ${strokeWidth}rem;
      }
   `}

    path {
      fill: white;
    }

    ${({ isFAQsIcon }) =>
      isFAQsIcon &&
      `
      path {
        fill: none;
        stroke: white;
      }

      circle {
        stroke: white;
        stroke-width: 0.11rem;
      }
    `}

    ${({ isReportsIcon }) =>
      isReportsIcon &&
      `
      path {
        fill: none;
      }
    `}
  }

  a {
    color: ${colors.white};
    font-weight: 600;
    text-decoration: none;
  }

  h4 {
    color: white;
    font-weight: 600;
    margin-top: 0.15rem;
  }
`;

export const MenuLink = styled(NavLink)`
  align-items: center;
  border-radius: 0.375rem;
  color: ${colors.white};
  display: flex;
  font-weight: 500;
  height: 3rem;
  justify-content: start;
  padding: 0.5rem 0.8rem;
  text-decoration: none;
  white-space: nowrap;
  margin-left: 2rem;
  padding: 0;

  &.active {
    ${({ $isCollapsed }) => !$isCollapsed && `border-right: 0.35rem ${colors.green} solid;`}
    ${({ $isCollapsed }) => !$isCollapsed && `border-radius: 0;`}
    ${({ $isCollapsed }) => $isCollapsed && `background-color: ${colors.green};`}
    ${({ $isCollapsed }) => $isCollapsed && `margin-right:1rem;`}
  }
`;

export const DashboardLink = styled(MenuLink)`
  margin-left: 0;
`;
export const Container = styled.div`
  margin-left: 2rem;
  padding: 0;
`;

export const LineSeparator = styled.hr`
  margin: 0;
  height: 0.125rem;
  min-height: 0.125rem;
  background-color: ${colors.mercury};
  border: none;
`;
