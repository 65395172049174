/* eslint-disable no-nested-ternary */
import { ReactComponent as ClaimAddressIcon } from 'assets/icons/claim-address.svg';
import { ReactComponent as EventsIcon } from 'assets/icons/events-icon.svg';
import { ReactComponent as knowledgeCenterIcon } from 'assets/icons/knowledgeCenter/idea.svg';
import { ReactComponent as QuestionIcon } from 'assets/icons/knowledgeCenter/questionIcon.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg';
import { ReactComponent as RequestsIcon } from 'assets/icons/request-icon.svg';
import { ReactComponent as UserRole } from 'assets/icons/user-roles.svg';
import { ReactComponent as Subscriptions } from 'assets/icons/subscriptions.svg';
import { ReactComponent as Up } from 'assets/chevron-up.svg';
import { ReactComponent as Down } from 'assets/chevron-down.svg';
import { ReactComponent as Bids } from 'assets/icons/bid.svg';
import { ReactComponent as Revenues } from 'assets/icons/revenues.svg';
import { ReactComponent as Listings } from 'assets/icons/listings.svg';
import { ReactComponent as Billings } from 'assets/icons/billings.svg';
import { ReactComponent as NoteXchange } from 'assets/icons/NoteXchange.svg';
import { ReactComponent as Reports } from 'assets/icons/reports.svg';
import { ReactComponent as DashboardSVG } from 'assets/icons/dashboard-icon.svg';
import Reverifi from 'assets/icons/Reverifi.png';
import { useUser } from 'contexts/UserContext';
import PropType from 'prop-types';
import React, { useState } from 'react';
import { getStrokeWidth } from 'utils/helpers';
import {
  DashboardMenuLink,
  ExpandableContainer,
  ExpandableMenuButton,
  LeftArrowIcon,
  LinkTitle,
  LogoutLink,
  MenuArrow,
  MenuIconContainer,
  MenuItem,
  MenuItemsContainer,
  MenuLink,
  MenuWrapper,
  RightArrowIcon,
} from './sidebar.styles';

const noteLinks = [{ Icon: Listings, title: 'Notes', to: '/noteXchange/notes' }];

const reverifiLinks = [
  { Icon: Reports, title: 'Reports', to: '/reports' },
  { Icon: UserRole, title: 'Users', to: '/users' },
  { Icon: EventsIcon, title: 'Events', to: '/events' },
  { Icon: ClaimAddressIcon, title: 'Users Claims', to: '/claim-address' },
  { Icon: Bids, title: 'Bids', to: '/bids' },
  { Icon: Revenues, title: 'Agents’ Revenues', to: '/agents-revenues' },
  { Icon: Listings, title: 'All Listings', to: '/listings' },
  { Icon: Listings, title: 'Exclusive Listings', to: '/exclusive-listings' },
  { Icon: Subscriptions, title: 'Subscriptions', to: '/subscriptions' },
  { Icon: Billings, title: 'Billings', to: '/billings' },
  { Icon: QuestionIcon, title: 'FAQs', to: '/FAQs' },
  { Icon: knowledgeCenterIcon, title: 'Knowledge Center', to: '/knowledge-center' },
  { Icon: RequestsIcon, title: 'Requests', to: '/requests' },
];

/**
 * Side menu.
 *
 * @return {JSX.Element}
 */
function Sidebar({ isCollapsed, setIsCollapsed }) {
  const { logout } = useUser();
  const [noteExpanded, setNoteExpanded] = useState(false);
  const [revExpanded, setRevExpanded] = useState(false);

  return (
    <MenuItemsContainer isCollapsed={isCollapsed}>
      <MenuArrow onClick={() => setIsCollapsed(!isCollapsed)}>
        <MenuIconContainer isCollapsed={isCollapsed}>
          {isCollapsed ? <LeftArrowIcon /> : <RightArrowIcon />}
        </MenuIconContainer>
      </MenuArrow>

      <MenuWrapper>
        <DashboardMenuLink isCollapsed={isCollapsed} to="/">
          {!isCollapsed ? (
            <MenuItem type="button">
              <DashboardSVG />
            </MenuItem>
          ) : (
            <p>Dashboard</p>
          )}
        </DashboardMenuLink>
      </MenuWrapper>

      {/* NoteXchange */}
      <ExpandableMenuButton
        isCollapsed={isCollapsed}
        onClick={() => setNoteExpanded(!noteExpanded)}
      >
        {!isCollapsed && <NoteXchange />}
        {isCollapsed ? (
          noteExpanded ? (
            <>
              <span>NoteXchange</span>
              <Up />
            </>
          ) : (
            <>
              <span>NoteXchange</span>
              <Down />
            </>
          )
        ) : null}
      </ExpandableMenuButton>
      <ExpandableContainer isExpanded={noteExpanded} isCollapsed={isCollapsed}>
        {noteLinks.map(({ Icon, title, to }) => (
          <MenuLink key={title} $isCollapsed={isCollapsed} to={to}>
            <MenuItem
              type="button"
              isFAQsIcon={title === 'FAQs'}
              isReportsIcon={title === 'Reports'}
              strokeWidth={getStrokeWidth(title)}
            >
              <Icon />
            </MenuItem>
            <LinkTitle>{title}</LinkTitle>
          </MenuLink>
        ))}
      </ExpandableContainer>

      {/* Reverifi */}
      <ExpandableMenuButton isCollapsed={isCollapsed} onClick={() => setRevExpanded(!revExpanded)}>
        {!isCollapsed && <img src={Reverifi} alt="logo" />}
        {isCollapsed ? (
          revExpanded ? (
            <>
              <span>Reverifi</span> <Up />
            </>
          ) : (
            <>
              <span>Reverifi</span> <Down />
            </>
          )
        ) : null}
      </ExpandableMenuButton>
      <ExpandableContainer isExpanded={revExpanded} isCollapsed={isCollapsed}>
        {reverifiLinks.map(({ Icon, title, to }) => (
          <MenuLink key={title} $isCollapsed={isCollapsed} to={to}>
            <MenuItem
              type="button"
              isFAQsIcon={title === 'FAQs'}
              isReportsIcon={title === 'Reports'}
              strokeWidth={getStrokeWidth(title)}
            >
              <Icon />
            </MenuItem>
            <LinkTitle>{title}</LinkTitle>
          </MenuLink>
        ))}
      </ExpandableContainer>

      <LogoutLink onClick={() => logout()}>
        <MenuItem type="button">
          <LogoutIcon />
        </MenuItem>
        <LinkTitle>Log Out</LinkTitle>
      </LogoutLink>
    </MenuItemsContainer>
  );
}

Sidebar.propTypes = {
  isCollapsed: PropType.bool.isRequired,
  setIsCollapsed: PropType.func.isRequired,
};
export default Sidebar;
