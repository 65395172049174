import { ReactComponent as LeftArrow } from 'assets/icons/visit-left-arrow.svg';
import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import colors from 'styles/colors';
import mq from 'styles/media-query';

const fadeIn = keyframes`
  from {
    max-width: 15rem;
  }

  to {
    max-width: 5rem;
  }
`;

const fadeOut = keyframes`
  from {
    max-width: 5rem;
  }

  to {
    max-width: 20rem;
  }
`;

export const MenuItemsContainer = styled.div`
  display: none;
  min-height: 100vh;

  ${mq.tablet`
     animation: ${({ isCollapsed }) => {
       if (isCollapsed === true) return fadeOut;
       if (isCollapsed === false) return fadeIn;
       return '';
     }}
    ease-in-out forwards;

  animation-duration: 0.4s;
  display: flex;
  flex-direction: column;
  background-color: ${colors.mineShaft};
  margin-top: 0.1rem;
  padding: 5rem 0 0 1rem;
  max-width: 20rem;
  width: 100%;
  `}
`;

export const MenuLink = styled(NavLink)`
  align-items: center;
  border-radius: 0.375rem;
  color: ${colors.white};
  display: flex;
  font-weight: 500;
  justify-content: start;
  padding: 0.5rem 0.8rem;
  text-decoration: none;
  white-space: nowrap;

  &.active {
    ${({ $isCollapsed }) => !$isCollapsed && `border-right: 0.35rem ${colors.green} solid;`}
    ${({ $isCollapsed }) => !$isCollapsed && `border-radius: 0;`}
    ${({ $isCollapsed }) => $isCollapsed && `background-color: ${colors.green};`}
    ${({ $isCollapsed }) => $isCollapsed && `margin-right:1rem;`}
  }
`;

export const DashboardMenuLink = styled(MenuLink)`
  p {
    @keyframes show {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    animation-name: show;
    animation-duration: 1s;
  }
`;
export const LinkTitle = styled.p`
  margin-left: 1.8rem;
  overflow: hidden;
`;

export const LeftArrowIcon = styled(LeftArrow)`
  width: 1rem;
  height: 1rem;

  path {
    fill: ${colors.mineShaft};
  }
`;

export const RightArrowIcon = styled(LeftArrowIcon)`
  transform: rotate(180deg);
  margin-left: 0.1rem;
`;

export const ItemsContainer = styled.p`
  margin-left: 2rem;
  overflow: hidden;
`;

export const MenuItem = styled.span`
  cursor: pointer;
  background-color: inherit;
  padding: 0;
  border: none;
  position: relative;

  svg {
    max-width: 1.8rem;
    max-height: 1.6rem;
    width: auto;

    ${({ strokeWidth }) =>
      strokeWidth &&
      `
      g {
        stroke: white;
        stroke-width: ${strokeWidth}rem;
      }
   `}

    path {
      fill: white;
    }

    ${({ isFAQsIcon }) =>
      isFAQsIcon &&
      `
      path {
        fill: none;
        stroke: white;
      }

      circle {
        stroke: white;
        stroke-width: 0.11rem;

      }
    `}

    ${({ isReportsIcon }) =>
      isReportsIcon &&
      `
      path {
        fill: none;
      }
    `}
  }
`;

export const MenuArrow = styled.span`
  cursor: pointer;
  padding: 0;
  border: none;
  position: relative;
`;

export const MenuIconContainer = styled.div`
  animation: ease-in-out forwards;

  animation-duration: 0.4s;
  background-color: ${colors.white};
  border-radius: 20rem;
  border: 0.15rem ${colors.midGray} solid;
  box-shadow: none;
  display: flex;
  padding: 0.5rem;
  position: absolute;
  top: -4rem;
  right: -1rem;

  &:hover {
    background-color: ${colors.green};
    border: 0.15rem ${colors.green} solid;

    > svg {
      path {
        fill: ${colors.white};
      }
    }
  }
`;

export const LogoutLink = styled.div`
  align-items: center;
  border-radius: 0.375rem;
  color: ${colors.white};
  display: flex;
  font-weight: 500;
  height: 4rem;
  justify-content: start;
  padding: 0.5rem 0.8rem;
  text-decoration: none;
  white-space: nowrap;
  margin-top: 2rem;
  line-height: 2rem;
  cursor: pointer;

  p {
    margin: 0 0 0.5rem 1.8rem;
  }

  &.active {
    ${({ isCollapsed }) => !isCollapsed && `border-right: 0.35rem ${colors.green} solid;`}
    ${({ isCollapsed }) => !isCollapsed && `border-radius: 0;`}
    ${({ isCollapsed }) => isCollapsed && `background-color: ${colors.green};`}
    ${({ isCollapsed }) => isCollapsed && `margin-right:1rem;`}
  }
`;

export const ExpandableContainer = styled.div`
  animation: ${({ isExpanded }) => (isExpanded ? fadeOut : fadeIn)}, ease-in-out;

  animation-duration: 0.4s;
  display: ${({ isExpanded }) => (isExpanded ? 'flex' : 'none')};
  flex-direction: column;
  margin: 2rem 0 0 ${({ isCollapsed }) => (isCollapsed ? '2.4rem' : '0')};
  max-height: ${({ isExpanded }) => (isExpanded ? '100%' : '0')};
  overscroll-behavior: contain;
`;

export const ExpandableMenuButton = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: ${colors.green};
  cursor: pointer;
  display: flex;
  font-size: 1.15rem;
  font-weight: 500;
  height: 2.25rem;
  justify-content: space-between;
  padding: ${({ isCollapsed }) => (isCollapsed ? '0 2.4rem 0 1rem' : '0 0.5rem')};

  span {
    @keyframes show {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    animation-name: show;
    animation-duration: 1s;
  }

  &:not(:first-of-type) {
    margin-top: 2rem;
  }

  svg {
    height: 1.56rem;
    width: 1.56rem;
  }

  img {
    height: 2.25rem;
    width: 2.25rem;
  }
`;

export const MenuWrapper = styled.div`
  margin-bottom: 0.8125rem;
`;
