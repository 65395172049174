import { apiUrl } from 'config/config';
import http from 'utils/http';
import { reverifiApplicationId } from '../utils/constants';

/**
 * Service that update user roles
 *
 * @param {Array(String)} rolesIds roles ids array
 *
 * @return {Object[]} Array of roles.
 */
export const getUserData = async (id) => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/users/${id}`);

  return data;
};

/**
 * Service that get users with pagination.
 *
 * @param {Number} limit     Number of users.
 * @param {Number} page      Page number.
 * @param {String} userInfo  User phone, email or name.
 * @param {String} roleType  Role type.
 * @param {String} startDate Start date that users registered.
 * @param {String} endDate   End date that users registered.
 * @param {String} sort      Sorting Received data.
 *
 * @return {Object} Users data[] & count.
 */
export const getPaginatedUsers = async (
  limit,
  page,
  userInfo = '',
  roleType = '',
  startDate = '',
  endDate = '',
  sort = 'ASC',
  appId = null
) => {
  const { data } = await http.get(
    `${apiUrl}/users/all/?page=${page}&limit=${limit}&order=${sort}&userInfo=${userInfo}&role=${roleType}&startDate=${startDate}&endDate=${endDate}`,
    {
      headers: {
        application: appId || reverifiApplicationId,
      },
    }
  );
  return data;
};

/**
 * Service that get excel file buffer with filtration.
 *
 * @param {Number} limit     Number of users.
 * @param {Number} page      Page number.
 * @param {String} userInfo  User phone, email or name.
 * @param {String} roleType  Role type.
 * @param {String} startDate Start date that users registered.
 * @param {String} endDate   End date that users registered.
 * @param {String} sort      Sorting Received data.
 *
 * @return {Object} Blob.
 */
export const exportFilteredUsers = async (
  limit,
  page,
  userInfo = '',
  roleType = '',
  startDate = '',
  endDate = '',
  sort = 'ASC'
) => {
  const { data } = await http.get(
    `${apiUrl}/users/export/filtered?page=${page}&limit=${limit}&order=${sort}&userInfo=${userInfo}&role=${roleType}&startDate=${startDate}&endDate=${endDate}`,
    {
      responseType: 'blob',
    }
  );
  return data;
};

/**
 * Service that get single user info
 *
 * @return {Object[]} Array of info data.
 */
export const getUserInfo = async (id) => {
  const path = id ? `/${id}` : '';

  const {
    data: { data },
  } = await http.get(`${apiUrl}/user-info${path}`);

  return data;
};

/**
 * Service that get users with limit
 *
 * @param {Number} limit number of users
 * @param {String} name agent name
 *
 * @return {Object[]} Array of users.
 */
export const getUsersWithLimit = async (limit, name) => {
  const {
    data: { data },
  } = await http.get(`${apiUrl}/users/all/${limit}?name=${name}`);

  return data;
};

/**
 * Service that get users creating statistic
 *
 * @param {Number} startYear startYear
 * @param {Boolean} yearly yearly
 * @param {Boolean} free free
 * @param {Number} endYear endYear
 *
 * @return {Object[]} Array of users.
 */
export const getUsersStatistic = async (startYear, free = '', yearly = '', endYear = '') => {
  const {
    data: { data },
  } = await http.get(
    `${apiUrl}/users/statistics/?startYear=${startYear}&yearly=${yearly}&endYear=${endYear}&free=${free}`
  );

  return data;
};

/**
 * Service that get top agents for app by applicationId.
 *
 * @param {String} applicationId free
 * @param {Number} page page
 * @param {Number} limit limit
 *
 * @return {Object[]} Array of users.
 */
export const getTopAgents = async (applicationId, page = 1, limit = 5) => {
  const {
    data: { data },
  } = await http.get(
    `${apiUrl}/users/top-agents?applicationId=${applicationId}&page=${page}&limit=${limit}`
  );

  return data;
};

/**
 * Service that get Users Typesfor app by applicationId.
 *
 * @param {String} applicationId free
 *
 * @return {Object[]} Array of users.
 */
export const getUsersTypes = async (applicationId) => {
  const { data: { data, totalUserCount = 0 } = {} } = await http.get(
    `${apiUrl}/users/users-type/statistics?applicationId=${applicationId}`
  );

  return { data, totalUserCount };
};

/**
 * Service that get all users reports
 *
 * @param {Date} startDate  startDate of Users.
 * @param {Date} endDate  endDate of Users.
 * @param {String} userInfo  User phone, email or name.
 * @param {Date} roleId  User role Id.
 * @param {String} appId  applicationId.
 *
 * @return {Array} users reports
 */
export const getAllUsersReports = async (
  startDate = '',
  endDate = '',
  userInfo = '',
  roleId = '',
  appId = reverifiApplicationId
) => {
  const { data } = await http.get(
    `${apiUrl}/users/report?startDate=${startDate}&endDate=${endDate}&username=${userInfo}&roleId=${roleId}&applicationId=${appId}`
  );
  return data;
};

/**
 * Service that get excel file buffer with filtration.
 *
 * @param {Date} startDate  startDate of Users.
 * @param {Date} endDate  endDate of Users.
 * @param {String} userInfo  User phone, email or name.
 * @param {Date} roleId  User role Id.
 * @param {String} appId  applicationId.
 *
 * @return {Object} Blob.
 */
export const exportUsersReports = async (
  startDate = '',
  endDate = '',
  userInfo = '',
  roleId = '',
  appId = reverifiApplicationId
) => {
  const { data } = await http.get(
    `${apiUrl}/users/report/export?startDate=${startDate}&endDate=${endDate}&username=${userInfo}&roleId=${roleId}&applicationId=${appId}`,
    {
      responseType: 'blob',
    }
  );
  return data;
};

/**
 * Service that get all users revenues
 *
 * @param {Number} page   Page number.
 * @param {Number} limit  Number of Users.
 * @param {String} sort   Sorting Received data.
 * @param {String} userInfo  User phone, email or name.
 *
 * @return {Array} users revenues
 */
export const getAllUsersEarning = async (page = 1, limit = 10, userInfo = '', sort = 'DESC') => {
  const { data } = await http.get(
    `${apiUrl}/user-earning/all?page=${page}&limit=${limit}&userName=${userInfo}&order=${sort}`
  );
  return data;
};

/**
 * Service that get users revenues by Id
 *
 * @param {String} id.
 *
 * @return {Array} user revenue
 */
export const getEarningById = async (id) => {
  const { data } = await http.get(`${apiUrl}/user-earning/${id}`);
  return data;
};

/**
 * Service that update users revenues by Id
 *
 * @param {String} id.
 *
 * @return {Object} updated user revenue
 */
export const updateUserEarningStatus = async (id) => {
  const {
    data: { data },
  } = await http.patch(`${apiUrl}/user-earning/${id}`);

  return data;
};
