import { useUser } from 'contexts/UserContext';
import PropTypes from 'prop-types';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

/**
 * Component to warp pages with auth guard.
 *
 * @param {JSX.Element} props.children The page component to wrap.
 *
 * @return {JSX.Element}
 */
function RequireAuth({ children }) {
  const { isLoggedIn } = useUser();
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RequireAuth;
