/* eslint-disable sort-keys */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import { ReactComponent as BecomeRealtor } from 'assets/icons/knowledgeCenter/become-realtor.svg';
import { ReactComponent as Construction } from 'assets/icons/knowledgeCenter/construction.svg';
import { ReactComponent as HomeBuying } from 'assets/icons/knowledgeCenter/home-buying.svg';
import { ReactComponent as HomeInsurance } from 'assets/icons/knowledgeCenter/home-insurance.svg';
import { ReactComponent as HomeSelling } from 'assets/icons/knowledgeCenter/home-selling.svg';
import { ReactComponent as Investing } from 'assets/icons/knowledgeCenter/investing.svg';
import { ReactComponent as Mortgage } from 'assets/icons/knowledgeCenter/mortgage.svg';
import { ReactComponent as PersonalDevelopment } from 'assets/icons/knowledgeCenter/personal-development.svg';
import { ReactComponent as RealEstateAgent } from 'assets/icons/knowledgeCenter/real-estate-agent.svg';
import { ReactComponent as RealEstateLaw } from 'assets/icons/knowledgeCenter/real-estate-law.svg';
import { ReactComponent as TitleInsurance } from 'assets/icons/knowledgeCenter/title-insurance.svg';
import { serverUrl } from 'config/config';
import { generateLabelValuePairs } from 'utils/helpers';

export const DEFAULT_PAGE_LIMIT = 8;
export const EVENT_TABLE_LIMIT = 10;
export const timerMinutes = 120;
export const NOT_APPLICABLE = 'N/A';

export const transactionStepsNames = {
  addParties: { name: 'Add Involved Parties', route: 'add-parties' },
  assignTasks: { name: 'Manage & Assign Tasks', route: 'assign-tasks' },
  closeDeal: { name: 'Finalize and Close Deal', route: 'close-deal' },
  uploadDocuments: { name: 'Upload Documents', route: 'upload-documents' },
};

export const invitationStatus = {
  accepted: 'Accepted',
  declined: 'Declined',
  waiting: 'Waiting',
};

export const searchOptions = {
  componentRestrictions: { country: ['us'] },
};

export const transactionStatus = {
  canceled: 'canceled',
  closed: 'closed',
  inProgress: 'in progress',
};

export const transactionTaskStatus = {
  completed: 'Completed',
  inProgress: 'In progress',
  todo: 'Todo',
};

export const transactionRoles = {
  buyer: 'Buyer',
  buyerAgent: 'Buyer Agent',
  buyerAttorney: 'Buyer Attorney',
  coordinator: 'Transaction Coordinator',
  homeInsurance: 'Home Insurance',
  lender: 'Lender',
  seller: 'Seller',
  sellerAgent: 'Seller Agent',
  sellerAttorney: 'Seller Attorney',
  titleInsurance: 'Title Insurance',
};

/**
 * Enum for action types.
 */
export const actionTypes = Object.freeze({
  closeDeal: 'Close deal',
  completeProfile: 'Complete profile',
  completeRegistration: 'Registration',
  createNewListing: 'New listing',
  dailyLogin: 'Login',
});

export const stageUrl = serverUrl;

export const achievementsData = {
  completeProfile: {
    id: 1,
    link: '/my-profile',
    points: '40',
    text: 'Complete your profile to get',
  },
  newListing: {
    id: 2,
    link: '/my-listings',
    points: '80',
    text: 'Add your first listing to get',
  },
};

export const notificationTypes = {
  cancelTransaction: 'Cancel Transaction',
  claimAddress: 'Claim Address',
  closeDeal: 'Close Deal',
  contactAgent: 'Contact Agent',
  contactAgentListing: 'Contact Agent Listing',
  forgotPassword: 'Forgot Password',
  newsletterSubscribe: 'Newsletter Subscribe',
  presenterInvite: 'Presenter Invitation',
  presenterUserInvite: 'Presenter User Invitation',
  registration: 'Registration',
  replayCounterOffer: 'Replay Counter Offer',
  replayCounterOfferSender: 'Replay Counter Offer Sender',
  review: 'Review',
  scheduleVisit: 'Schedule Visit',
  submitCounterOffer: 'Submit Counter Offer',
  submitCounterOfferSender: 'Submit Counter Offer Sender',
  submitOffer: 'Submit Offer',
  submitOfferSender: 'Submit Offer Sender',
  transactionInvitation: 'Transaction Invitation',
  transactionProcessAssign: 'Transaction Process Assign',
  transactionProcessCompleteTask: 'Transaction Process Complete Task',
  transactionStart: 'Transaction Start',
  verifyPhone: 'Verify Phone',
  adminEventRequest: 'Admin Event Request',
  eventRequest: 'Event Request',
  replayEventRequest: 'Replay Event Request',
  approvedPost: 'Approved Post',
  rejectedPost: 'Rejected Post',
  acceptClaimAddress: 'Accept Claim Address',
  rejectClaimAddress: 'Reject Claim Address',
  approvedEvent: 'Approved Event',
  rejectedEvent: 'Rejected Event',
  userEditClaimAddress: 'User Edit Claim Address',
};

export const OFFER_STATUS = {
  accepted: 'accepted',
  pending: 'pending',
  rejected: 'rejected',
};

export const listingStatus = {
  acceptedOffer: 'Accepted Offer',
  clear: 'clear',
  sold: 'Sold',
  underContract: 'Under Contract',
};

export const VISIT_TYPES = {
  received: 'received',
  sent: 'sent',
};

export const claimAddressStatus = {
  accepted: 'Accepted',
  rejected: 'Rejected',
};

export const eventTypeOptions = generateLabelValuePairs(['type1', 'type2', 'type3']);

export const categoriesIcons = {
  'Home Buying': <HomeBuying />,
  'Home Selling': <HomeSelling />,
  Investing: <Investing />,
  'Real Estate Law': <RealEstateLaw />,
  'Home Insurance': <HomeInsurance />,
  'Title Insurance': <TitleInsurance />,
  'Becoming a Real Estate Agent': <BecomeRealtor />,
  'Real Estate Agent': <RealEstateAgent />,
  'Personal Development': <PersonalDevelopment />,
  'Residential Mortgages': <Mortgage />,
  Construction: <Construction />,
};

export const adminEventStatus = {
  draft: 'Draft',
  pending: 'Pending',
  published: 'Published',
  rejected: 'Rejected',
};

export const eventsStatus = {
  archive: 'Archived',
  draft: 'Draft',
  published: 'Published',
  rejected: 'Rejected',
  pending: 'Pending',
};

export const EventPrivacy = {
  private: 'Private',
  public: 'Public',
};

export const postStatus = {
  accepted: 'Accepted',
  rejected: 'Rejected',
};

export const requestTypes = {
  event: 'event',
  post: 'knowledge center contribution',
  claimAddress: 'claim address',
};

export const reverifiApplicationId = '714a0749-4bff-4da2-90e4-1b75a9dc5532';
export const noteXchangeApplicationId = 'cb881646-d2d8-4945-a990-87333fe62420';

export const rentTypeId = 'e7f4803a-8cbc-4028-8c9e-641644fe8b13';
export const saleTypeId = '0ec0b55b-0195-414b-bb69-3a7875aa2104';
