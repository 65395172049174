import Navbar from 'components/shared/Navbar';
import Sidebar from 'components/shared/Sidebar';
import Wrapper from 'components/shared/Wrapper';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import LayoutContainer from './layout.styles';

/**
 * Component to wrap page components with the side menu.
 *
 * @param {Object} props The component props.
 * @param {JSX.Element} props.children The child component to wrap.
 *
 * @return {JSX.Element}
 */
function Layout({ children }) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  return (
    <>
      <Navbar />
      <LayoutContainer>
        <Sidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
        <Wrapper isCollapsed={isCollapsed}>{children}</Wrapper>
      </LayoutContainer>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
